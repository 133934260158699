<template>
  <div class="column is-3 is-assortment">
    <div class="card">
      <v-lazy-image
        width="250"
        height="250"
        class="image"
        :src="require(`@/assets/images/assortment/${image}`)"
        :alt="imageAlt"
      />
      <article class="is-above-container">
        <h1 class="title is-6">{{ title }}</h1>
        <p class="subtitle is-6">{{ shortDescription }}</p>
      </article>
    </div>
  </div>
</template>

<script>
import VLazyImage from 'v-lazy-image';

export default {
  name: 'AssortmentItem',
  props: ['title', 'shortDescription', 'image', 'imageAlt'],
  components: {
    VLazyImage,
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: $white;
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  position: relative;
  filter: opacity(60%);
  transition: filter ease-in-out 0.3s;
  cursor: pointer;
  text-align: left;

  .is-above-container {
    padding: 0.75rem;
    z-index: 3;
    margin: 0 auto;
    position: absolute;
    width: 100%;
    height: 50%;
    background-color: $white-bis;
    bottom: 0;
    transform: translateY(65%);
    transition: transform ease-in-out 0.3s;
  }

  .subtitle {
    filter: opacity(0%);
    transition: filter ease-in-out 0.3s;
  }

  &:hover {
    filter: opacity(100%);
    transition: all ease-in-out 0.3s;

    .is-above-container {
      transform: translateY(0px);
    }

    .subtitle {
      filter: opacity(100%);
    }
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

@media only screen and (min-width: 769px) {
  .is-assortment {
    padding: 0;

    &:nth-child(4n + 5) {
      padding-left: 0.75rem;
    }

    &:nth-child(4n + 4) {
      padding-right: 0.75rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .is-assortment {
    padding: 0 1rem;
  }
}
</style>
